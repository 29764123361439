var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"my-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('forms.domain.bastion.title')))])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('label',{staticClass:"mr-2",attrs:{"for":"checkbox-bastion"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"door-closed","variant":"danger"}}),_vm._v(_vm._s(_vm.$t('forms.domain.bastion.disabled')))],1),_c('b-form-checkbox',{attrs:{"id":"checkbox-bastion","switch":""},model:{value:(_vm.bastion),callback:function ($$v) {_vm.bastion=$$v},expression:"bastion"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"door-open","variant":"success"}}),_vm._v(_vm._s(_vm.$t('forms.domain.bastion.enabled'))+" ")],1)],1)])],1),(_vm.showBastionOptions)?_c('span',[(_vm.bastionId)?_c('div',{staticClass:"d-flex align-items-start"},[_c('h5',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: ("" + _vm.copyTooltipText),
                       placement: 'top',
                       customClass: 'isard-tooltip',
                       trigger: 'hover' }),expression:"{ title: `${copyTooltipText}`,\n                       placement: 'top',\n                       customClass: 'isard-tooltip',\n                       trigger: 'hover' }"}],staticClass:"cursor-pointer w-fit-content",on:{"click":function($event){return _vm.copyToClipboard(_vm.bastionId)}}},[_c('b',[_vm._v("ID:")]),_vm._v(" "+_vm._s(_vm.bastionId)+" ")])]):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-bastion-http","switch":""},model:{value:(_vm.httpEnabled),callback:function ($$v) {_vm.httpEnabled=$$v},expression:"httpEnabled"}},[_vm._v(" "+_vm._s(_vm.$t('forms.domain.bastion.http.checkbox'))+" ")])],1)],1),(_vm.httpEnabled)?[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('label',{attrs:{"for":"httpPortField"}},[_vm._v(" "+_vm._s(_vm.$t('forms.domain.bastion.http.http-port'))+" "),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: _vm.$t('forms.domain.bastion.port-tooltip', { port: _vm.getConfig.httpPort }),
                             placement: 'top',
                             customClass: 'isard-tooltip isard-tooltip-lg',
                             trigger: 'hover' }),expression:"{ title: $t('forms.domain.bastion.port-tooltip', { port: getConfig.httpPort }),\n                             placement: 'top',\n                             customClass: 'isard-tooltip isard-tooltip-lg',\n                             trigger: 'hover' }"}],attrs:{"icon":"info-circle"}})],1)]),_c('b-col',{attrs:{"cols":"6","xl":"4"}},[_c('b-form-input',{attrs:{"id":"httpPortField","type":"number","size":"sm","placeholder":"80"},model:{value:(_vm.httpPort),callback:function ($$v) {_vm.httpPort=$$v},expression:"httpPort"}})],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('label',{attrs:{"for":"httpPortField"}},[_vm._v(" "+_vm._s(_vm.$t('forms.domain.bastion.http.https-port'))+" "),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: _vm.$t('forms.domain.bastion.port-tooltip', { port: _vm.getConfig.httpsPort }),
                             placement: 'top',
                             customClass: 'isard-tooltip isard-tooltip-lg',
                             trigger: 'hover' }),expression:"{ title: $t('forms.domain.bastion.port-tooltip', { port: getConfig.httpsPort }),\n                             placement: 'top',\n                             customClass: 'isard-tooltip isard-tooltip-lg',\n                             trigger: 'hover' }"}],attrs:{"icon":"info-circle"}})],1)]),_c('b-col',{attrs:{"cols":"6","xl":"4"}},[_c('b-form-input',{attrs:{"id":"httpsPortField","type":"number","size":"sm","placeholder":"443"},model:{value:(_vm.httpsPort),callback:function ($$v) {_vm.httpsPort=$$v},expression:"httpsPort"}})],1)],1)]:_vm._e(),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('b-form-checkbox',{attrs:{"id":"checkbox-bastion-ssh","switch":""},model:{value:(_vm.sshEnabled),callback:function ($$v) {_vm.sshEnabled=$$v},expression:"sshEnabled"}},[_vm._v(" "+_vm._s(_vm.$t('forms.domain.bastion.ssh.checkbox'))+" ")])],1)],1),(_vm.sshEnabled)?[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('label',{attrs:{"for":"sshPortField"}},[_vm._v(" "+_vm._s(_vm.$t('forms.domain.bastion.ssh.port'))+" "),_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: _vm.$t('forms.domain.bastion.port-tooltip', { port: _vm.getConfig.bastionSshPort }),
                             placement: 'top',
                             customClass: 'isard-tooltip isard-tooltip-lg',
                             trigger: 'hover' }),expression:"{ title: $t('forms.domain.bastion.port-tooltip', { port: getConfig.bastionSshPort }),\n                             placement: 'top',\n                             customClass: 'isard-tooltip isard-tooltip-lg',\n                             trigger: 'hover' }"}],attrs:{"icon":"info-circle"}})],1)]),_c('b-col',{attrs:{"cols":"6","xl":"4"}},[_c('b-form-input',{attrs:{"id":"sshPortField","type":"number","size":"sm","placeholder":"22"},model:{value:(_vm.sshPort),callback:function ($$v) {_vm.sshPort=$$v},expression:"sshPort"}})],1)],1),_c('b-row',{staticClass:"mt-2 mb-4"},[_c('b-col',{attrs:{"cols":"4","xl":"2"}},[_c('label',{staticClass:"mb-0",attrs:{"for":"sshAuthorizedKeysField"}},[_vm._v(_vm._s(_vm.$t('forms.domain.bastion.ssh.authorized-keys')))])]),_c('b-col',{attrs:{"cols":"6","xl":"4"}},[_c('b-form-textarea',{attrs:{"id":"sshAuthorizedKeysField","size":"sm","rows":"3","no-resize":"","placeholder":"ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQC7..."},model:{value:(_vm.sshAuthorizedKeys),callback:function ($$v) {_vm.sshAuthorizedKeys=$$v},expression:"sshAuthorizedKeys"}})],1)],1)]:_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }